/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
  font-family: "Volkswagen-Medium";
  src: local("Volkswagen-Medium"),
    url(./assets/fonts/Volkswagen-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Volkswagen-DemiBold";
  src: local("Volkswagen-DemiBold"),
    url(./assets/fonts/Volkswagen-DemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Lato-Regular";
  src: local("Lato-Regular"),
    url(./assets/fonts/Lato-Regular.ttf) format("truetype");
}

/* 
@font-face {
  font-family: "OpenSans-Bold";
  src: local("OpenSans-Bold"),
    url(./assets/fonts/OpenSans-Bold.ttf) format("truetype");
} */
@font-face {
  font-family: "OpenSans-Regular";
  src: local("OpenSans-Regular"),
    url(./assets/fonts/OpenSans-Regular.ttf) format("truetype");
}

body {
background-color: #F2F1F1
}

.container {
  padding-left: 2%;
  padding-right: 2%;
}

@layer components {
  .social-login-btn {
    @apply w-6 h-6 rounded-lg items-center justify-center flex cursor-pointer active:opacity-50 text-primary text-2xl
  }

  .input-classic {
    @apply border-borderGray rounded border w-7/12 h-10
  }

  .btn-effects {
    @apply cursor-pointer active:opacity-50 hover:opacity-80
  }

  .btn-1 {
    @apply bg-primary w-40 h-10 items-center justify-center flex rounded-lg text-white text-xl
  }

  .btn-2 {
    @apply bg-red-500 w-40 h-10 items-center justify-center flex rounded-lg text-white text-xl
  }

  .public-hover {
    @apply bg-white hover:bg-secondary hover:bg-opacity-40
  }

  .text-hover {
    @apply hover:text-secondary cursor-pointer
  }

  .btn-hover {
    @apply hover:opacity-80
  }


}


 


/*  
  html {
    font-size: 14px;
  } */

  /* @media screen and (max-width: 1366px) {
    html {
      font-size: 14px;
    }
  } */
  /* @media screen and (max-width: 1199px) {
    html {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 992px) {
    html {
      font-size: 17px;
    }
  } */