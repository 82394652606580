@font-face {
  font-family: 'Poppins';
  src: url('../../assets/fonts/Poppins-Regular.ttf') format('truetype');
}

* {
  font-family: 'Poppins';
}

html {
  scroll-behavior: smooth;
}
